export const getPayouts = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: [
          {
            id: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
            object: "payout",
            amount: 548368,
            arrival_date: 1621221609,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1621218009,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "pending",
          },
          {
            id: "po_1Iq6mQIoBfvEUBiwu2wYnO27",
            object: "payout",
            amount: 192914,
            arrival_date: 1620951669,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620948609,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "in_transit",
          },
          {
            id: "po_1IpkIdIoBfvEUBiwHJwlLnLe",
            object: "payout",
            amount: 188444,
            arrival_date: 1620691200,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620696134,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IpNmQIoBfvEUBiwmLrlwLl9",
            object: "payout",
            amount: 787800,
            arrival_date: 1620604800,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620609570,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IoIEtIoBfvEUBiwUicNTclp",
            object: "payout",
            amount: 258864,
            arrival_date: 1620345600,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620349943,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1InvtAIoBfvEUBiwHbj9lGAN",
            object: "payout",
            amount: 750838,
            arrival_date: 1620259200,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620264028,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1InZPQIoBfvEUBiwdtpAYC7C",
            object: "payout",
            amount: 268624,
            arrival_date: 1620172800,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620177616,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1InCyNIoBfvEUBiwI5LGDi7O",
            object: "payout",
            amount: 179056,
            arrival_date: 1620086400,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620091371,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1ImqRdIoBfvEUBiwsKz8WJCa",
            object: "payout",
            amount: 262745,
            arrival_date: 1620000000,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1620004773,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IlkwuIoBfvEUBiwHcxGN1dO",
            object: "payout",
            amount: 128302,
            arrival_date: 1619740800,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619745320,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IlOblIoBfvEUBiwYnd1evUy",
            object: "payout",
            amount: 724638,
            arrival_date: 1619654400,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619659440,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1Il25gIoBfvEUBiwYan0Qy6b",
            object: "payout",
            amount: 210413,
            arrival_date: 1619568000,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619572884,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IkfhzIoBfvEUBiwjGZw53ty",
            object: "payout",
            amount: 257637,
            arrival_date: 1619481600,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619486847,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IkJ9yIoBfvEUBiws8vP9a4U",
            object: "payout",
            amount: 198643,
            arrival_date: 1619395200,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619400170,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IjDYVIoBfvEUBiwCJiPAU0S",
            object: "payout",
            amount: 436628,
            arrival_date: 1619136000,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619140299,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1Iir9eIoBfvEUBiw8nrsD1eK",
            object: "payout",
            amount: 676211,
            arrival_date: 1619049600,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1619054190,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IiUi5IoBfvEUBiwzJRYklDf",
            object: "payout",
            amount: 309666,
            arrival_date: 1618963200,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1618967913,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1Ii8EkIoBfvEUBiwTwOkIlme",
            object: "payout",
            amount: 407909,
            arrival_date: 1618876800,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1618881526,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1Ihlk8IoBfvEUBiwO2gSeaIM",
            object: "payout",
            amount: 180117,
            arrival_date: 1618790400,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1618795060,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
          {
            id: "po_1IggE7IoBfvEUBiwZxQtFMBZ",
            object: "payout",
            amount: 301554,
            arrival_date: 1618531200,
            bank_account: {
              id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
              bank_name: "UnionBank/SAVINGS",
              created: 1456221361,
              last4: "8413",
            },
            created: 1618535527,
            currency: "php",
            method: "standard",
            sent_out_of_band: false,
            status: "paid",
          },
        ],
        has_more: true,
      });
    }, 300);
  });
};

export const getPayoutDetails = (payoutId: string) => {
  const data = [
    {
      id: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
      object: "payout",
      amount: 548368,
      arrival_date: 1621221609,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1621218009,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "pending",
    },
    {
      id: "po_1Iq6mQIoBfvEUBiwu2wYnO27",
      object: "payout",
      amount: 192914,
      arrival_date: 1620951669,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620948609,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "in_transit",
    },
    {
      id: "po_1IpkIdIoBfvEUBiwHJwlLnLe",
      object: "payout",
      amount: 188444,
      arrival_date: 1620691200,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620696134,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IpNmQIoBfvEUBiwmLrlwLl9",
      object: "payout",
      amount: 787800,
      arrival_date: 1620604800,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620609570,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IoIEtIoBfvEUBiwUicNTclp",
      object: "payout",
      amount: 258864,
      arrival_date: 1620345600,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620349943,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1InvtAIoBfvEUBiwHbj9lGAN",
      object: "payout",
      amount: 750838,
      arrival_date: 1620259200,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620264028,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1InZPQIoBfvEUBiwdtpAYC7C",
      object: "payout",
      amount: 268624,
      arrival_date: 1620172800,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620177616,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1InCyNIoBfvEUBiwI5LGDi7O",
      object: "payout",
      amount: 179056,
      arrival_date: 1620086400,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620091371,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1ImqRdIoBfvEUBiwsKz8WJCa",
      object: "payout",
      amount: 262745,
      arrival_date: 1620000000,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1620004773,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IlkwuIoBfvEUBiwHcxGN1dO",
      object: "payout",
      amount: 128302,
      arrival_date: 1619740800,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619745320,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IlOblIoBfvEUBiwYnd1evUy",
      object: "payout",
      amount: 724638,
      arrival_date: 1619654400,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619659440,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1Il25gIoBfvEUBiwYan0Qy6b",
      object: "payout",
      amount: 210413,
      arrival_date: 1619568000,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619572884,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IkfhzIoBfvEUBiwjGZw53ty",
      object: "payout",
      amount: 257637,
      arrival_date: 1619481600,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619486847,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IkJ9yIoBfvEUBiws8vP9a4U",
      object: "payout",
      amount: 198643,
      arrival_date: 1619395200,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619400170,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IjDYVIoBfvEUBiwCJiPAU0S",
      object: "payout",
      amount: 436628,
      arrival_date: 1619136000,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619140299,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1Iir9eIoBfvEUBiw8nrsD1eK",
      object: "payout",
      amount: 676211,
      arrival_date: 1619049600,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1619054190,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IiUi5IoBfvEUBiwzJRYklDf",
      object: "payout",
      amount: 309666,
      arrival_date: 1618963200,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1618967913,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1Ii8EkIoBfvEUBiwTwOkIlme",
      object: "payout",
      amount: 407909,
      arrival_date: 1618876800,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UnionBank/SAVINGS",
        created: 1456221361,
        last4: "8413",
      },
      created: 1618881526,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1Ihlk8IoBfvEUBiwO2gSeaIM",
      object: "payout",
      amount: 180117,
      arrival_date: 1618790400,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UNIONBANK/Savings",
        created: 1456221361,
        last4: "8413",
      },
      created: 1618795060,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
    {
      id: "po_1IggE7IoBfvEUBiwZxQtFMBZ",
      object: "payout",
      amount: 301554,
      arrival_date: 1618531200,
      bank_account: {
        id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
        bank_name: "UNIONBANK/Savings",
        created: 1456221361,
        last4: "8413",
      },
      created: 1618535527,
      currency: "php",
      method: "standard",
      sent_out_of_band: false,
      status: "paid",
    },
  ];
  const payout = data.filter((p) => p.id === payoutId)[0];
  if (payout) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          id: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
          object: "payout",
          amount: 548368,
          arrival_date: 1620950400,
          auto: true,
          automatic: true,
          balance_transaction: {
            id: "txn_1IqpUBIoBfvEUBiwBWbJo9di",
            object: "balance_transaction",
            amount: -548368,
            available_on: 1620954398,
            created: 1620954398,
            currency: "php",
            description: "STRIPE PAYOUT",
            exchange_rate: null,
            fee: 0,
            fee_details: [],
            net: -548368,
            reporting_category: "payout",
            source: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
            status: "available",
            type: "payout",
          },
          bank_account: {
            id: "ba_17hcthIoBfvEUBiwHZKjHGzB",
            object: "bank_account",
            account_holder_name: null,
            account_holder_type: null,
            bank_name: "UnionBank/SAVINGS",
            country: "PH",
            created: 1456221361,
            currency: "php",
            fingerprint: "cPolmRayiRJOJHa9",
            last4: "8413",
            routing_number: "010419995",
            status: "new",
            validation_warnings: {},
          },
          created: 1620954398,
          currency: "php",
          description: "STRIPE PAYOUT",
          destination: "ba_17hcthIoBfvEUBiwHZKjHGzB",
          failure_balance_transaction: null,
          failure_code: null,
          failure_message: null,
          has_mt_receipt: false,
          has_sv_receipt: false,
          livemode: true,
          metadata: {},
          method: "standard",
          original_payout: null,
          owning_merchant: "acct_173PceIoBfvEUBiw",
          owning_merchant_info: "acct_173PceIoBfvEUBiw",
          reversed_by: null,
          sent_out_of_band: false,
          source_type: "card",
          summary: {
            adjustment_count: 1,
            adjustment_fees: 1500,
            adjustment_gross: -36751,
            anticipation_repayment_summary_count: 0,
            anticipation_repayment_summary_gross: 0,
            balance_statement: {
              ending_balance: null,
              primary_transfer: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
              primary_transfer_amount: 548368,
              starting_balance: null,
            },
            balance_transfer_summary_count: 0,
            balance_transfer_summary_gross: 0,
            billing_invoice_payment_count: 0,
            billing_invoice_payment_fees: 0,
            billing_invoice_payment_gross: 0,
            charge_count: 66,
            charge_fees: 24743,
            charge_gross: 630706,
            collected_fee_count: 0,
            collected_fee_gross: 0,
            collected_fee_refund_count: 0,
            collected_fee_refund_gross: 0,
            contribution_summary_count: 0,
            contribution_summary_gross: 0,
            currency: "php",
            financing_summary_count: 0,
            financing_summary_gross: 0,
            net: 548368,
            network_cost_payment_count: 0,
            network_cost_payment_fees: 0,
            network_cost_payment_gross: 0,
            refund_count: 19,
            refund_fees: -735,
            refund_gross: -20079,
            reserve_adjustment_count: 0,
            reserve_adjustment_fees: 0,
            reserve_adjustment_gross: 0,
            retried_transfer_net: 0,
            topup_count: 0,
            topup_gross: 0,
            topup_reversal_count: 0,
            topup_reversal_gross: 0,
            transfer_count: 0,
            transfer_fees: 0,
            transfer_gross: 0,
            validation_count: 0,
            validation_fees: 0,
          },
          statement_descriptor: null,
          status: "paid",
          type: "bank_account",
        });
      }, 300);
    });
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({
          error: {
            code: "resource_missing",
            doc_url: "https://stripe.com/docs/error-codes/resource-missing",
            message: `No such payout: '${payoutId}'`,
            message_code: "missing_resource_livemode",
            param: "id",
            type: "invalid_request_error",
          },
        });
      }, 300);
    });
  }
};

export const getPayoutEvents = (payoutId: string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        object: "list",
        data: [
          {
            id: "evt_1Iqv7OIoBfvEUBiwxL79X7mJ",
            object: "event",
            api_version: "2019-03-14",
            created: 1620976050,
            data: {
              object: {
                id: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
                object: "payout",
                amount: 548368,
                arrival_date: 1620950400,
                automatic: true,
                balance_transaction: "txn_1IqpUBIoBfvEUBiwBWbJo9di",
                created: 1620954398,
                currency: "php",
                description: "STRIPE PAYOUT",
                destination: "ba_17hcthIoBfvEUBiwHZKjHGzB",
                failure_balance_transaction: null,
                failure_code: null,
                failure_message: null,
                livemode: true,
                metadata: {},
                method: "standard",
                original_payout: null,
                reversed_by: null,
                source_type: "card",
                statement_descriptor: null,
                status: "paid",
                type: "bank_account",
              },
            },
            is_object_log: false,
            is_summarized: false,
            is_unstable_event: false,
            livemode: true,
            pending_webhooks: 0,
            request: {
              id: null,
              idempotency_key: null,
            },
            type: "payout.paid",
            webhooks_archived: false,
          },
          {
            id: "evt_1IqpavIoBfvEUBiwL5Z6je6m",
            object: "event",
            api_version: "2019-03-14",
            created: 1620954817,
            data: {
              object: {
                id: "po_1IqpUBIoBfvEUBiwMu4uxgWQ",
                object: "payout",
                amount: 548368,
                arrival_date: 1620950400,
                automatic: true,
                balance_transaction: "txn_1IqpUBIoBfvEUBiwBWbJo9di",
                created: 1620954398,
                currency: "php",
                description: "STRIPE PAYOUT",
                destination: "ba_17hcthIoBfvEUBiwHZKjHGzB",
                failure_balance_transaction: null,
                failure_code: null,
                failure_message: null,
                livemode: true,
                metadata: {},
                method: "standard",
                original_payout: null,
                reversed_by: null,
                source_type: "card",
                statement_descriptor: null,
                status: "in_transit",
                type: "bank_account",
              },
            },
            is_object_log: false,
            is_summarized: false,
            is_unstable_event: false,
            livemode: true,
            pending_webhooks: 0,
            request: {
              id: null,
              idempotency_key: null,
            },
            type: "payout.created",
            webhooks_archived: false,
          },
        ],
        has_more: false,
        url: "/v1/events",
      });
    }, 300);
  });
};

export const getPayoutTransactions = (payoutId: string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        object: "list",
        data: [
          {
            id: "ad_1IqAJdIoBfvEUBiwpSmfOq4d",
            amount: -36751,
            created: 1620796141,
            currency: "php",
            customer_details: "bumlaw508@yahoo.com",
            description:
              "Chargeback withdrawal for ch_1IIUfcIoBfvEUBiwN0c2pNvh",
            fee: 1500,
            fee_details: [
              {
                amount: 1500,
                application: null,
                currency: "php",
                description: "Dispute fee",
                type: "stripe_fee",
              },
            ],
            net: -38251,
            type: "adjustment",
          },
          {
            id: "ch_1IpaNAIoBfvEUBiwibtEeKPy",
            amount: -720,
            created: 1620779974,
            currency: "php",
            customer_details: "Mara Garcia",
            description: null,
            fee: -24,
            fee_details: [
              {
                amount: -24,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -696,
            type: "refund",
          },
          {
            id: "ch_1IpV7qIoBfvEUBiwNZPWi83Q",
            amount: -322,
            created: 1620779965,
            currency: "php",
            customer_details: "Franco Lao",
            description: null,
            fee: -11,
            fee_details: [
              {
                amount: -11,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -311,
            type: "refund",
          },
          {
            id: "ch_1IpUnaIoBfvEUBiw9uf08CxE",
            amount: -3797,
            created: 1620779957,
            currency: "php",
            customer_details: "HELEN GAYAPA",
            description: null,
            fee: -130,
            fee_details: [
              {
                amount: -130,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -3667,
            type: "refund",
          },
          {
            id: "ch_1IpGY3IoBfvEUBiw9fzTLLJ3",
            amount: -282,
            created: 1620779947,
            currency: "php",
            customer_details: "Bessie Rivera",
            description: null,
            fee: -10,
            fee_details: [
              {
                amount: -10,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -272,
            type: "refund",
          },
          {
            id: "ch_1IpCctIoBfvEUBiwJQbuhJB4",
            amount: -603,
            created: 1620779938,
            currency: "php",
            customer_details: "Margarette Beltran",
            description: null,
            fee: -21,
            fee_details: [
              {
                amount: -21,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -582,
            type: "refund",
          },
          {
            id: "ch_1IpCPuIoBfvEUBiwD9Xpy85V",
            amount: -2073,
            created: 1620779930,
            currency: "php",
            customer_details: "Marissa San Diego",
            description: null,
            fee: -71,
            fee_details: [
              {
                amount: -71,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -2002,
            type: "refund",
          },
          {
            id: "ch_1IpABtIoBfvEUBiwuPuUrzoT",
            amount: -1980,
            created: 1620779921,
            currency: "php",
            customer_details: "ronette villareal",
            description: null,
            fee: -67,
            fee_details: [
              {
                amount: -67,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -1913,
            type: "refund",
          },
          {
            id: "ch_1Ip7IgIoBfvEUBiwiibhFDwl",
            amount: -522,
            created: 1620779909,
            currency: "php",
            customer_details: "mimi mercado",
            description: null,
            fee: -18,
            fee_details: [
              {
                amount: -18,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -504,
            type: "refund",
          },
          {
            id: "ch_1Ip59wIoBfvEUBiwhw3DYtqq",
            amount: -39,
            created: 1620779899,
            currency: "php",
            customer_details: "Carmina Petero",
            description: null,
            fee: -1,
            fee_details: [
              {
                amount: -1,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -38,
            type: "refund",
          },
          {
            id: "ch_1Ip0cUIoBfvEUBiwScLKRLfk",
            amount: -51,
            created: 1620779890,
            currency: "php",
            customer_details: "Hazel Yu",
            description: null,
            fee: -2,
            fee_details: [
              {
                amount: -2,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -49,
            type: "refund",
          },
          {
            id: "ch_1IopI2IoBfvEUBiwPSCqq5nP",
            amount: -45,
            created: 1620779879,
            currency: "php",
            customer_details: "WILLIAM TANADA JR",
            description: null,
            fee: -2,
            fee_details: [
              {
                amount: -2,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -43,
            type: "refund",
          },
          {
            id: "ch_1IoolkIoBfvEUBiwAhOmCmgE",
            amount: -5049,
            created: 1620779868,
            currency: "php",
            customer_details: "Sherlene Ngaw",
            description: null,
            fee: -172,
            fee_details: [
              {
                amount: -172,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -4877,
            type: "refund",
          },
          {
            id: "ch_1IonWwIoBfvEUBiwWQC4cdMz",
            amount: -3059,
            created: 1620779861,
            currency: "php",
            customer_details: "Joy Ng",
            description: null,
            fee: -104,
            fee_details: [
              {
                amount: -104,
                application: null,
                currency: "php",
                description: "Stripe processing fee refund",
                type: "stripe_fee",
              },
            ],
            net: -2955,
            type: "refund",
          },
          {
            id: "ch_1IphksIoBfvEUBiwHaaDTaQP",
            amount: 85753,
            created: 1620686354,
            currency: "php",
            customer_details: "Maejoy Obach",
            description: "Donation: SOWSV",
            fee: 2966,
            fee_details: [
              {
                amount: 2966,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 82787,
            type: "charge",
          },
          {
            id: "ch_1IpfIUIoBfvEUBiw54E3qCLx",
            amount: 458,
            created: 1620676906,
            currency: "php",
            customer_details: "BERNARD PAUL MAGLALANG",
            description: null,
            fee: 66,
            fee_details: [
              {
                amount: 66,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 392,
            type: "charge",
          },
          {
            id: "ch_1Ipf01IoBfvEUBiwu5n5kvd1",
            amount: 12663,
            created: 1620675761,
            currency: "php",
            customer_details: "Ma Blesila Pena",
            description: null,
            fee: 481,
            fee_details: [
              {
                amount: 481,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 12182,
            type: "charge",
          },
          {
            id: "ch_1IpdP4IoBfvEUBiwAdGu8o4f",
            amount: 540,
            created: 1620669626,
            currency: "php",
            customer_details: "Ella Villanueva",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpdOzIoBfvEUBiw6fMICuyR",
            amount: 540,
            created: 1620669621,
            currency: "php",
            customer_details: "Kristine Borlongan",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpdOwIoBfvEUBiwnUmd9sfm",
            amount: 540,
            created: 1620669618,
            currency: "php",
            customer_details: "Guenalyn Manlunas",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpdOsIoBfvEUBiwiFWHpkyL",
            amount: 540,
            created: 1620669614,
            currency: "php",
            customer_details: "April Capuz",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpdOmIoBfvEUBiw5aVmiuOm",
            amount: 540,
            created: 1620669608,
            currency: "php",
            customer_details: "Roxanne Vivienne Gallardo",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpdOiIoBfvEUBiw4WYFCtZi",
            amount: 540,
            created: 1620669604,
            currency: "php",
            customer_details: "Celina Miranda",
            description: "Magpie 1 Month Subscription",
            fee: 68,
            fee_details: [
              {
                amount: 68,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 472,
            type: "charge",
          },
          {
            id: "ch_1IpaNAIoBfvEUBiwibtEeKPy",
            amount: 15092,
            created: 1620657976,
            currency: "php",
            customer_details: "Mara Garcia",
            description: null,
            fee: 563,
            fee_details: [
              {
                amount: 563,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 14529,
            type: "charge",
          },
          {
            id: "ch_1IpaL1IoBfvEUBiwAPP2rJ8D",
            amount: 3956,
            created: 1620657843,
            currency: "php",
            customer_details: "JENNIFER RECIO",
            description: null,
            fee: 185,
            fee_details: [
              {
                amount: 185,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 3771,
            type: "charge",
          },
          {
            id: "ch_1IpWscIoBfvEUBiwaLwvbdKM",
            amount: 11107,
            created: 1620644550,
            currency: "php",
            customer_details: "Jesyl Hangca",
            description: null,
            fee: 428,
            fee_details: [
              {
                amount: 428,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 10679,
            type: "charge",
          },
          {
            id: "ch_1IpVe0IoBfvEUBiwNJHFug19",
            amount: 5508,
            created: 1620639800,
            currency: "php",
            customer_details: "Cristopher Belisario",
            description: null,
            fee: 237,
            fee_details: [
              {
                amount: 237,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 5271,
            type: "charge",
          },
          {
            id: "ch_1IpVMnIoBfvEUBiwPRCMGXCw",
            amount: 2643,
            created: 1620638733,
            currency: "php",
            customer_details: "maurice beronilla",
            description: null,
            fee: 140,
            fee_details: [
              {
                amount: 140,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 2503,
            type: "charge",
          },
          {
            id: "ch_1IpV7qIoBfvEUBiwNZPWi83Q",
            amount: 5697,
            created: 1620637806,
            currency: "php",
            customer_details: "Franco Lao",
            description: null,
            fee: 244,
            fee_details: [
              {
                amount: 244,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 5453,
            type: "charge",
          },
          {
            id: "ch_1IpUnaIoBfvEUBiw9uf08CxE",
            amount: 12873,
            created: 1620636550,
            currency: "php",
            customer_details: "HELEN GAYAPA",
            description: null,
            fee: 488,
            fee_details: [
              {
                amount: 488,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 12385,
            type: "charge",
          },
          {
            id: "ch_1IpUkAIoBfvEUBiw6EdUXtJj",
            amount: 3744,
            created: 1620636338,
            currency: "php",
            customer_details: "Jogi Gratil",
            description: null,
            fee: 177,
            fee_details: [
              {
                amount: 177,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 3567,
            type: "charge",
          },
          {
            id: "ch_1IpUdhIoBfvEUBiwVCMH3WsL",
            amount: 36900,
            created: 1620635937,
            currency: "php",
            customer_details: "oliversanjuanph@gmail.com",
            description: "Payment for 36 - Credit Unit Bundled Course",
            fee: 1305,
            fee_details: [
              {
                amount: 1305,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 35595,
            type: "charge",
          },
          {
            id: "ch_1IpTw1IoBfvEUBiwohwCSx37",
            amount: 36900,
            created: 1620633229,
            currency: "php",
            customer_details: "reianne@rbsantoslaw.com",
            description: "Payment for 36 - Credit Unit Bundled Course",
            fee: 1305,
            fee_details: [
              {
                amount: 1305,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 35595,
            type: "charge",
          },
          {
            id: "ch_1IpSFCIoBfvEUBiwseWJ9cpc",
            amount: 1108,
            created: 1620626730,
            currency: "php",
            customer_details: "Alma Tienzo",
            description: null,
            fee: 88,
            fee_details: [
              {
                amount: 88,
                application: null,
                currency: "php",
                description: "Stripe processing fees",
                type: "stripe_fee",
              },
            ],
            net: 1020,
            type: "charge",
          },
        ],
        has_more: true,
        url: "/v1/transfers/po_1IqpUBIoBfvEUBiwMu4uxgWQ/transactions",
      });
    }, 300);
  });
};
