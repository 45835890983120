import { CustomerRequestType, CustomersPayloadType } from "@custom-types/Customers";
import { ApiResponse } from "apisauce";
import { createApiClient } from "./client";
import URIs from "./uri";

export const getCustomers = async (
  token: string,
  params?: CustomersPayloadType,
  url?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  if (url) {
    api.setBaseURL(url);
    return await api.get('', params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await api.get(URIs.CUSTOMERS, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

export const createCustomer = async (
  token: string,
  params: CustomerRequestType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(`${URIs.CUSTOMERS}`, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCustomerDetails = async (
  token: string,
  customerId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.CUSTOMER.replace("$1", customerId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const updateCustomer = async (
  token: string,
  customerId: string,
  params: CustomerRequestType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.put(
    `${URIs.CUSTOMER.replace("$1", customerId)}`,
    params,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getCustomerPayments = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: "pi_1GImckIoBfvEUBiwhvOsOlth",
          object: "dashboard.payments_list_item",
          amount: 2766,
          captured: false,
          charge_amount: null,
          charge_amount_refunded: null,
          charge_id: null,
          created: 1583287574,
          currency: "php",
          customer_deleted: false,
          customer_description: "Customer for Dominick Danao",
          customer_email: "dom@danao.co",
          customer_id: "zA6jvpmNWGtwcLkS",
          customer_name: null,
          customer_shipping_name: null,
          description: "Invoice 99558E98-0001",
          display_status: "incomplete",
          dispute_covered: null,
          livemode: true,
          receipt_email: null,
          refundable: false,
          refunded: null,
          source_name: null,
          title_color: "gray",
          tooltip: {
            amount_refunded: null,
            card_brand: null,
            charge_status: null,
            failure_message: null,
            has_attached_customer: true,
            is_acss_debit: false,
            is_customer_balance_payment: false,
            is_id_bank_transfer: false,
            is_payment_intent: null,
            is_unattempted_invoice: true,
            num_other_attempts: null,
            outcome_has_rule: null,
            outcome_reason: null,
            outcome_type: null,
            refund_pending_reason: null,
            seller_message: null,
            status: "requires_payment_method",
            type: "incomplete",
          },
        },
        {
          id: "ch_1EY5VOIoBfvEUBiwv7Ywp4Te",
          object: "dashboard.payments_list_item",
          amount: 300,
          captured: true,
          charge_amount: 300,
          charge_amount_refunded: 0,
          charge_id: "ch_1EY5VOIoBfvEUBiwv7Ywp4Te",
          created: 1557382402,
          currency: "php",
          customer_deleted: false,
          customer_description: "Customer for Dominick Danao",
          customer_email: "dom@danao.co",
          customer_id: "zA6jvpmNWGtwcLkS",
          customer_name: null,
          customer_shipping_name: null,
          description: "Professional fees",
          display_status: "succeeded",
          dispute_covered: null,
          livemode: true,
          receipt_email: "dom@danao.co",
          refundable: true,
          refunded: false,
          source_name: null,
          title_color: "dark",
          tooltip: {
            amount_refunded: null,
            card_brand: null,
            charge_status: null,
            failure_message: null,
            has_attached_customer: null,
            is_acss_debit: null,
            is_customer_balance_payment: null,
            is_id_bank_transfer: null,
            is_payment_intent: null,
            is_unattempted_invoice: null,
            num_other_attempts: null,
            outcome_has_rule: null,
            outcome_reason: null,
            outcome_type: null,
            refund_pending_reason: null,
            seller_message: null,
            status: null,
            type: "succeeded",
          },
        },
        {
          id: "ch_1EXlYgIoBfvEUBiwps8bOHvU",
          object: "dashboard.payments_list_item",
          amount: 5000,
          captured: true,
          charge_amount: 5000,
          charge_amount_refunded: 0,
          charge_id: "ch_1EXlYgIoBfvEUBiwps8bOHvU",
          created: 1557305726,
          currency: "php",
          customer_deleted: false,
          customer_description: "Customer for Dominick Danao",
          customer_email: "dom@danao.co",
          customer_id: "zA6jvpmNWGtwcLkS",
          customer_name: null,
          customer_shipping_name: null,
          description: "",
          display_status: "succeeded",
          dispute_covered: null,
          livemode: true,
          receipt_email: "dom@danao.co",
          refundable: true,
          refunded: false,
          source_name: null,
          title_color: "dark",
          tooltip: {
            amount_refunded: null,
            card_brand: null,
            charge_status: null,
            failure_message: null,
            has_attached_customer: null,
            is_acss_debit: null,
            is_customer_balance_payment: null,
            is_id_bank_transfer: null,
            is_payment_intent: null,
            is_unattempted_invoice: null,
            num_other_attempts: null,
            outcome_has_rule: null,
            outcome_reason: null,
            outcome_type: null,
            refund_pending_reason: null,
            seller_message: null,
            status: null,
            type: "succeeded",
          },
        },
      ]);
    }, 300);
  });
};
