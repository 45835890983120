import { ApiResponse } from "apisauce";
import { createApiClient, createDashboardClient } from "./client";
import URIs from "./uri";

export const sendWelcomeEmail = async (
  email: String
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_WELCOME_EMAIL,
    { email },
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const getDeveloperKeys = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.DEVELOPER_KEY,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
