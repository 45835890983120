import {
  ForgotPasswordPayloadType,
  ResetPasswordPayloadType,
  VerifyEmailPayloadType,
} from "@custom-types/Auth";
import {
  ChangePasswordParamsType,
  UpdateProfileParamsType,
} from "@custom-types/Account";
import { ApiResponse } from "apisauce";
import { createApiClient, createDashboardClient } from "./client";
import URIs from "./uri";
import { UserSession } from "@custom-types/common";

export const getSession = async (): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.get(
    URIs.USER,
    {},
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const updateSession = async (user: UserSession): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.put(
    URIs.UPDATE_USER,
    user,
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const me = async (token: string): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.ME,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const updateMe = async (
  token: string,
  data: UpdateProfileParamsType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(URIs.ME, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};


export const changeEnvironment = async (
  token: string,
  livemode: boolean
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(URIs.CHANGE_ENVIRONMENT, {livemode}, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const changePassword = async (
  token: string,
  data: ChangePasswordParamsType
) => {
  const api = createApiClient();
  return await api.post(URIs.CHANGE_PASSWORD, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const sendPasswordChangedEmail = async (
  email: string
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_PASSWORD_CHANGED_EMAIL,
    { email },
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const sendVerificationEmail = async (
  email: string
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_VERIFICATION_EMAIL,
    { email },
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const verifyEmail = async (
  params: VerifyEmailPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.VERIFY_EMAIL, params);
};

export const sendForgotPasswordEmail = async (
  params: ForgotPasswordPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(URIs.FORGOT_PASSWORD, params, {
    headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY },
  });
};

export const forgotPassword = async (
  key: string,
  params: ForgotPasswordPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.FORGOT_PASSWORD, params, {
    headers: { "x-api-key": key },
  });
};

export const resetPass = async (
  params: ResetPasswordPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(URIs.RESET_PASSWORD, params, {
    headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY },
  });
};

export const resetPassword = async (
  key: string,
  params: ResetPasswordPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.RESET_PASSWORD, params, {
    headers: { "x-api-key": key },
  });
};

export const uploadLogo = async (
  organizationId: string,
  logo: File
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  const formData = new FormData();
  formData.append("organization_id", organizationId);
  formData.append("logo", logo);

  return await api.post(URIs.UPLOAD_LOGO, formData, {
    headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY },
  });
};

export const send2faActivatedEmail = async (
  email: string,
  type: string,
  mobile_last_4?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_2FA_ACTIVATED_EMAIL,
    { email, type, mobile_last_4 },
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};

export const deactivate2faSMS = async (
  token: string,
  password: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.DEACTIVATE_2FA_SMS, {password}, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deactivate2faAuthenticator = async (
  token: string,
  password: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.DEACTIVATE_2FA_AUTHENTICATOR, {password}, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const send2faDeactivatedEmail = async (
  email: string,
  type: string,
  active_2fa: boolean,
  mobile_last_4?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_2FA_DEACTIVATED_EMAIL,
    { email, type, active_2fa, mobile_last_4 },
    { headers: { Authorization: process.env.NEXT_PUBLIC_DASHBOARD_API_KEY } }
  );
};