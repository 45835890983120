const URIs = {
  REGISTER: '/api/register',
  LOGIN: '/api/login',
  LOGIN_2FA: '/api/login2fa',
  LOGIN_2FA_RESEND: '/api/login/two_factor/resend',
  LOGOUT: '/api/logout',
  USER: '/api/user',
  UPDATE_USER: '/api/update_user',
  CHANGE_ENVIRONMENT: '/api/settings',
  ME: '/api/me',
  CHANGE_PASSWORD: '/api/me/change_password',
  SEND_PASSWORD_CHANGED_EMAIL: '/api/send_email/change_password',
  SEND_VERIFICATION_EMAIL: '/api/send_email/verify_email',
  VERIFY_EMAIL: '/api/verify_email',
  FORGOT_PASSWORD: '/api/forgot_password',
  RESET_PASSWORD: '/api/reset_password',

  TEAMS: '/api/teams',
  SWITCH_TEAM: '/api/teams/$1/switch',
  CURRENT_TEAM: '/api/current_team',
  TEAM_MEMBERS: '/api/current_team/members',
  TEAM_INVITATIONS: '/api/current_team/invitations',
  INVITATION_DETAILS: '/api/team_invitations/$1',
  ACCEPT_INVITATION: '/api/team_invitations/$1/accept',
  INVITE_MEMBERS: '/api/current_team/invite',
  RESEND_INVITATION: '/api/team_invitations/$1/resend',
  UPDATE_TEAM_MEMBER: '/api/current_team/members/$1',
  REMOVE_TEAM_MEMBER: '/api/current_team/members/$1',
  UPDATE_TEAM: '/api/current_team/update',
  CHANGE_ROLE_EMAIL: '/api/send_email/change_role',
  PAYMENT_METHOD_SETTINGS: '/api/payment_methods',

  
  UPLOAD_LOGO: '/api/upload_logo',
  DELETE_FILE: '/api/delete_file',

  ONBOARDING: '/api/onboarding/',
  SAVE_ONBOARDING_DATA: 'api/onboarding/save_data',
  UPLOAD_DOCUMENT: 'api/onboarding/upload_file',
  DELETE_DOCUMENT: 'api/onboarding/files/$1',
  OWNERS: '/api/onboarding/owners',
  BUSINESS_OWNER: '/api/onboarding/owners/$1',
  SUBMIT_ONBOARDING: 'api/onboarding/submit_for_approval',
  SEND_WELCOME_EMAIL: '/api/send_email/welcome',

  ACTIVATE_2FA: '/api/account/two_factor/$1/activate',
  VERIFY_2FA: '/api/account/two_factor/$1/activate/process',
  SEND_2FA_ACTIVATED_EMAIL: '/api/send_email/activate_2fa',
  DEACTIVATE_2FA_SMS: '/api/account/two_factor/sms/deactivate',
  DEACTIVATE_2FA_AUTHENTICATOR:
    '/api/account/two_factor/authenticator/deactivate',
  SEND_2FA_DEACTIVATED_EMAIL: '/api/send_email/deactivate_2fa',
  SEND_NEW_MERCHANT_EMAIL: '/api/send_email/new_merchant',
  
  CUSTOMERS: '/api/customers',
  CUSTOMER: '/api/customers/$1',

  PAYMENTS: '/api/payments',
  EXPORT_PAYMENTS: '/api/payments/export',
  PAYMENT: '/api/payments/$1',
  REFUND: '/api/payments/$1/refund',
  VOID_PAYMENT: '/api/payments/$1/void',
  CAPTURE_PAYMENT: '/api/payments/$1/capture',

  DEVELOPER_KEY: '/api/settings/developer/keys',

  LIST_PAYMENT_REQUESTS: '/api/payment-requests',
  PAYMENT_REQUESTS: '/api/v1/requests',
  PAYMENT_REQUEST: '/api/v1/requests/$1',
  PAYMENT_REQUEST_EVENTS: '/api/v1/requests/$1/events',
  VOID_REQUEST: '/api/v1/requests/$1/void',
  RESEND_REQUEST: '/api/v1/requests/$1/resend',
  SEND_RECEIPT: '/api/send_email/receipt',
  SEND_REFUND_RECEIPT: '/api/send_email/refund',

  PAYMENT_LINKS: '/api/v1/links',
  PAYMENT_LINK: '/api/v1/links/$1',
  DEACTIVATE_LINK: '/api/v1/links/$1/deactivate',
  ACTIVATE_LINK: '/api/v1/links/$1/activate',
  PAYMENT_LINK_TRANSACTIONS: '/api/v1/links/$1/transactions',
  EXPORT_LINK_TRANSACTION: '/api/v1/links/$1/transactions/export',

  PRODUCTS: '/api/products',
  PRODUCT: '/api/products/$1',
  PRODUCT_IMAGE: '/api/products/$1/images/$2',
  PRODUCT_SIGNED_URL: '/api/uploads/get-signed-url',
};

export default URIs;
