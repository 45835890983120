import { PaymentRequestPayloadType } from "@custom-types/PaymentRequest";
import { ApiResponse } from "apisauce";
import { createApiClient, createPaymentRequestClient } from "./client";
import URIs from "./uri";



export const getPaymentRequests = async (
  token: string,
  status?: string,
  url?: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  if (url) {
    api.setBaseURL(url);
    return await api.get('', undefined, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    const params: { status?: string } = {};
    if (status) {
      params.status = status;
    }
    return await api.get(URIs.LIST_PAYMENT_REQUESTS, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

};

export const createPaymentRequest = async (
  key: string,
  params: PaymentRequestPayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.PAYMENT_REQUESTS, params);
};

export const getPaymentRequestDetails = async (
  key: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.get(URIs.PAYMENT_REQUEST.replace("$1", requestId));
};

export const getPaymentRequestEvents = async (
  key: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.get(URIs.PAYMENT_REQUEST_EVENTS.replace("$1", requestId));
};

export const updatePaymentRequestMetadata = async (
  key: string,
  requestId: string,
  metadata: any,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.put(URIs.PAYMENT_REQUEST.replace("$1", requestId), { metadata });
};

export const voidPaymentRequest = async (
  key: string,
  requestId: string,
  reason: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.VOID_REQUEST.replace("$1", requestId), { reason });
};

export const resendPaymentRequest = async (
  key: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.RESEND_REQUEST.replace("$1", requestId));
};