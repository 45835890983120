import { PaymentLinkPayloadType } from "@custom-types/PaymentLink";
import { ApiResponse } from "apisauce";
import { createPaymentLinkClient } from "./client";
import URIs from "./uri";



export const getPaymentLinks = async (
  key: string,
  status?: string,
  url?: string
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  if (url) {
    api.setBaseURL(url);
    return await api.get('');
  } else {
    const params: { status?: string } = {};
    if (status) {
      params.status = status;
    }
    return await api.get(URIs.PAYMENT_LINKS, params);
  }
};

export const createPaymentLink = async (
  key: string,
  params: PaymentLinkPayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  return await api.post(URIs.PAYMENT_LINKS, params);
};

export const getPaymentLinkDetails = async (
  key: string,
  linkId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  return await api.get(URIs.PAYMENT_LINK.replace("$1", linkId));
};

export const updatePaymentLink = async (
  key: string,
  linkId: string,
  params: PaymentLinkPayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  return await api.put(URIs.PAYMENT_LINK.replace("$1", linkId), params);
};

export const deactivatePaymentLink = async (
  key: string,
  linkId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  return await api.post(URIs.DEACTIVATE_LINK.replace("$1", linkId), {});
};

export const activatePaymentLink = async (
  key: string,
  linkId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  return await api.post(URIs.ACTIVATE_LINK.replace("$1", linkId), {});
};

export const getPaymentLinkTransactions = async (
  key: string,
  linkId: string,
  url?: string,
  status?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  if (url) {
    api.setBaseURL(url);
    return await api.get('');
  } else {
    const params: { status?: string } = {};
    if (status) {
      params.status = status;
    }
    return await api.get(URIs.PAYMENT_LINK_TRANSACTIONS.replace("$1", linkId), params);
  }
};

export const exportLinkTransactions = async (
  key: string,
  linkId: string,
  status?: string,
  from?: string,
  to?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentLinkClient(key);
  const params: { status?: string, from?: string, to?: string } = {};
  if (status) {
    params.status = status;
  }
  if (from) {
    params.from = from;
  }
  if (to) {
    params.to = to;
  }
  return await api.get(URIs.EXPORT_LINK_TRANSACTION.replace("$1", linkId), params);
}