import { ProductPayloadType, ProductUpdatePayloadType } from "@custom-types/Products";
import { ApiResponse } from "apisauce";
import { createApiClient } from "./client";
import URIs from "./uri";

export const getProducts = async (
  token: string,
  url?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  if (url) {
    api.setBaseURL(url);
    return await api.get('', {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await api.get(URIs.PRODUCTS, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

export const getProductDetails = async (
  token: string,
  productId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.PRODUCT.replace("$1", productId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getProductSignedUrl = async (
  token: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.PRODUCT_SIGNED_URL,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const uploadProductImage = async (
  signedUrl: string,
  image: File,
  imageType: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();

  return await api.put(
    signedUrl,
    image,
    {
      headers: {
        'Content-Type': imageType
      },
    }
  );
};

export const createProduct = async (
  token: string,
  params: ProductPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.PRODUCTS, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateProduct = async (
  token: string,
  productId: string,
  params: ProductUpdatePayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(URIs.PRODUCT.replace('$1', productId), params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteProduct = async (
  token: string,
  productId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.delete(
    URIs.PRODUCT.replace("$1", productId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const deleteProductImage = async (
  token: string,
  productId: string,
  imageId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.delete(
    URIs.PRODUCT_IMAGE.replace("$1", productId).replace('$2', imageId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};